@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap');


:root{
    /* --red:#ff5a3c; */
    --red:#4169E1;
    --light-red:#f9edeb;
}

*{
    font-family: 'Nunito', sans-serif;
    margin:0; padding:0;
    box-sizing: border-box;
    outline: none; border:none;
    text-decoration: none;
    /* text-transform: capitalize; */
}

html{
    font-size: 62.5%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-padding-top: 7rem;
}

body{
    background:#f7f7f7;
}

section{
    padding:2rem 9%;
}

.btn{
    margin-top: 1rem;
    display: inline-block;
    padding:.8rem 3.5rem;
    border-radius: .5rem;
    font-size: 1.7rem;
    color:var(--red);
    background:var(--light-red);
    cursor: pointer;
    text-align: center;
}

.btn:hover{
    background:var(--red);
    color:#fff;
}

.heading{
    text-align: center;
    color:#333;
    font-size: 4rem;
    padding-bottom: 3rem;
}

.heading span{
    color:var(--red);
    /* background:var(--light-red); */
    border-radius: .5rem;
    padding:.2rem 1.5rem;
}

.heading2{
    text-align: center;
    color:#333;
    font-size: 3.5rem;
    padding-bottom: 3rem;
}

.heading2 span{
    color:var(--red);
    /* background:var(--light-red); */
    border-radius: .5rem;
    padding:.2rem 1.5rem;
}

header{
    position: fixed;
    top:0; left:0; right:0;
    z-index: 1000;
    background:#fff;
    padding:1.5rem 9%;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

header .logo{
  font-weight: bolder;
  font-size: 2.5rem;
  color:#333;
}
header .logo img{
  width: 20vh;
}


header .logo span{
    color:var(--red);
}

header .navbar a{
    color:#333;
    font-size: 2rem;
    padding:0 1.5rem;
}

header .navbar a:hover{
    color:var(--red);
}

header .icons #menu-bars,
header .icons a{
    height:4.5rem;
    line-height: 4.5rem;
    width:5rem;
    font-size: 2rem;
    margin-right: .5rem;
    border-radius: .5rem;
    color:var(--red);
    /* background:var(--light-red); */
    text-align: center;
}

header .icons #menu-bars:hover,
header .icons a:hover{
    background-color:var(--red);
    color:#fff;
}

header .icons #menu-bars{
    display: none;
}

.home{
    min-height: 100vh;
    background:url(../public/image1/Tulipppp.jpg) no-repeat;
    background-size: cover;
    /* background-position: center; */
    /* background-attachment: fixed; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.home form{
    margin-top: 7rem;
    margin-left: 35rem;
    /* margin-right: 80rem; */
    background:#fff;
    border-radius: .5rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.2);
    border:.1rem solid rgba(0,0,0,.2);
    /* width:70rem; */
    width:40rem;
    padding:2rem;
}

.home form h3{
    text-align: center;
    color:#333;
    font-size: 3rem;
}

.home form .buttons-container{
    display: flex;
    gap:1.5rem;
}

.home form .buttons-container .btn{
    flex:1;
}

.home form .inputBox{
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
    margin-top: .5rem;
}

.home form .inputBox input,
.home form .inputBox select{
    flex:1 1 25rem;
    border:.1rem solid #333;
    box-shadow: 0 .5rem 1.5rem rgba(0,0,0,.1);
    font-size: 1.5rem;
    padding:1rem;
    text-transform: none;
}

.home form .btn{
    width: 100%;
    margin-top: .5rem;
}

.services .box-container{
    display: flex;
    flex-wrap: wrap;
    gap:1.5rem;
}

.services .box-container .box{
    flex:1 1 30rem;
    border-radius: .5rem;
    background:#fff;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    border:.1rem solid rgba(0,0,0,.2);
    text-align: center;
    padding:2rem;
}

.services .box-container .box h3{
    font-size: 2.5rem;
    color:#333;
}

.services .box-container .box p{
    font-size: 1.5rem;
    color:#666;
    padding:.5rem 0;
    line-height: 2;
}

.featured .box-container{
    display: flex;
    flex-wrap: wrap;
    gap:1.5rem;
}

.featured .box-container .box{
    border:.1rem solid rgba(0,0,0,.2);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    border-radius: .5rem;
    overflow:hidden;
    background:#fff;
    flex:1 1 30rem;
}

.featured .box-container .box .image-container{
    overflow:hidden;
    position: relative;
    width: 100%;
    height: 25rem;
}

.featured .box-container .box .image-container img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .2s linear;
}

.featured .box-container .box:hover .image-container img{
    transform: scale(1.1);
}

.featured .box-container .box .image-container .info{
    position: absolute;
    top:1rem; left:0;
    display: flex;
}

.featured .box-container .box .image-container .info h3{
    font-weight: 500;
    font-size: 1.4rem;
    color:#fff;
    background:rgba(0,0,0,.3);
    border-radius: .5rem;
    padding:.5rem 1.5rem;
    margin-left: 1rem;
}

.featured .box-container .box .image-container .icons{
    position: absolute;
    bottom:1rem; right:0;
    display: flex;
}

.featured .box-container .box .image-container .icons a{
    font-size: 1.4rem;
    color:#fff;
    display: flex;
    border-radius: .5rem;
    background: rgba(0,0,0,.3);
    margin-right: 1rem;
    padding:.7rem;
}

.featured .box-container .box .image-container .icons a h3{
    font-weight: 500;
    padding-left: .5rem;
}

.featured .box-container .box .image-container .icons a:hover{
    background:var(--red);
}

.featured .box-container .box .content{
    padding:1.5rem;
}

.featured .box-container .box .content .price{
    display: flex;
    align-items: center;
}

.featured .box-container .box .content .price h3{
    color:var(--red);
    font-size: 2rem;
    margin-right: auto;
}

.featured .box-container .box .content .price a{
    color:#666;
    font-size: 1.5rem;
    margin-right: .5rem;
    border-radius: .5rem;
    height:4rem;
    width:4rem;
    line-height: 4rem;
    text-align: center;
    background:#f7f7f7;
}

.featured .box-container .box .content .price a:hover{
    background:var(--red);
    color:#fff;
}

.featured .box-container .box .content .location{
    padding:1rem 0;
}

.featured .box-container .box .content .location h3{
    font-size: 2.5rem;
    color:#333;
}

.featured .box-container .box .content .location p{
    font-size: 1.8rem;
    color:#222121;
    line-height: 1.5;
    padding-top: .5rem;
}

.featured .box-container .box .content .details{
    padding:.5rem 0;
    display: flex;
    
}

.featured .box-container .box .content .details h3{
    flex:1;
    padding:1rem;
    border:.1rem solid rgba(0,0,0,.1);
    color:black;
    font-size: 1.4rem;
}

.featured .box-container .box .content .details h3 i{
    color:#333;
    padding-left: .5rem;
}

.featured .box-container .box .content .buttons{
    display: flex;
    gap:1rem;
}

.featured .box-container .box .content .buttons .btn{
    flex:1;
    font-size: 1.5rem;
    font-weight: bold;
}

.agents .box-container{
    display: flex;
    flex-wrap: wrap;
    gap:1.5rem;
    justify-content: center;
}

.agents .box-container .box{
    background:#fff;
    text-align: center;
    border:.1rem solid rgba(0,0,0,.2);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    border-radius: .5rem;
    padding:2rem;
    position: relative;
    width:26rem;
}

.agents .box-container .box .fa-envelope{
    position: absolute;
    top:1.8rem; left:2rem;
    color:#333;
    font-size: 2rem;
}

.agents .box-container .box .fa-envelope:hover{
    color:var(--red);
}

.agents .box-container .box .fa-phone{
    position: absolute;
    top:1.8rem; right:2rem;
    color:#333;
    font-size: 2rem;
}

.agents .box-container .box .fa-phone:hover{
    color:var(--red);
}

.agents .box-container .box img{
    border-radius: 50%;
    box-shadow: 0 0 0 .5rem rgba(0,0,0,.1);
    object-fit: cover;
    height:13rem;
    width:13rem;
    margin:1rem 0;
}

.agents .box-container .box h3{
    font-size: 2rem;
    color:#333;
}

.agents .box-container .box span{
    font-size: 1.7rem;
    color:var(--red);
}

.agents .box-container .box .share{
    padding-top: 2rem;
}

.agents .box-container .box .share a{
    margin:0 .3rem;
    height:4rem;
    width:4rem;
    line-height: 4rem;
    background:#f7f7f7;
    color:#666;
    border-radius: .5rem;
    font-size: 2rem;
}

.agents .box-container .box .share a:hover{
    background:var(--red);
    color:#fff;
}

.contact .icons-container{
    display: flex;
    flex-wrap: wrap;
    gap:1.5rem;
    padding-bottom: 2rem;
}

.contact .icons-container .icons{
    flex:1 1 25rem;
    background: #fff;
    padding:2rem;
    border:.1rem solid rgba(0,0,0,.2);
    border-radius: .5rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    text-align: center;
}

.contact .icons-container .icons img{
    height:6rem;
}

.contact .icons-container .icons h3{
    font-size: 2.5rem;
    color:#333;
    padding:1rem 0;
}

.contact .icons-container .icons p{
    font-size: 1.5rem;
    color:#666;
    padding:.2rem 0;
}

.contact .row{
    background: #fff;
    border:.1rem solid rgba(0,0,0,.2);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    border-radius: .5rem;
    display: flex;
    flex-wrap: wrap-reverse;
    padding:1rem;
}

.contact .row form{
    flex:1 1 50rem;
    padding:.5rem 1rem;
}

.contact .row .map{
    flex:1 1 30rem;
    width:100%;
    padding:1rem;
}

.contact .row form .inputBox{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.contact .row form textarea,
.contact .row form .inputBox input{
    margin-top: 1rem;
    padding:1rem;
    font-size: 1.7rem;
    color:#333;
    background: #f7f7f7;
    border-radius: .5rem;
    text-transform: none;
    width:49%;
}

.contact .row form textarea{
    width:100%;
    height:20rem;
    resize: none;
}

.contact .row form textarea:focus,
.contact .row form .inputBox input:focus{
    background-color: var(--light-red);
}

.footer .footer-container{
    background:#fff;
    border:.1rem solid rgba(0,0,0,.2);
    border-radius: .5rem;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
    padding:2rem;
}
 
.footer .footer-container .box-container{
    display: flex;
    flex-wrap: wrap;
    gap:1.5rem;
}

.footer .footer-container .box-container .box{
    flex:1 1 25rem;
}

.footer .footer-container .box-container .box h3{
    font-size: 2.5rem;
    color:#333;
    padding:.5rem 0;
}

.footer .footer-container .box-container .box a{
    display: block;
    font-size: 1.5rem;
    color:#666;
    padding:.5rem 0;
}

.footer .footer-container .box-container .box a:hover{
    color:var(--red);
    text-decoration: underline;
}

.footer .footer-container .credit{
    margin-top: 2rem;
    padding:.5rem;
    padding-top: 2.5rem;
    font-size: 2rem;
    text-align: center;
    color:#333;
    border-top: .1rem solid rgba(0,0,0,.1);
}

.footer .footer-container .credit span{
    color:var(--red);
}

.footer .footer-container {
    margin-bottom: 8rem;
}


.Tabbs{
  font-size: 15px;
  font-family: 'Nunito', sans-serif;
  color: #474646;
  
}

.tabs {
  display: flex;
  /* border-bottom: 1px solid #ccc; */
  margin-bottom: 20px;
  font-size: 15px;
  
}

.tab {
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.3s, border-bottom 0.3s;
}

.tab.active {
  background-color: #ffffff;
  border-bottom: 2px solid #007bff;
}

.tab:hover {
  background-color: #f1f1f1;
}

.Tabbs .content p{
  font-size: 15px;
  /* font-style: oblique; */
  margin-bottom: 10px;
  margin: 16px;
  
}
.Tabbs .content ul{
  margin-left: 2.7%;
}
.content {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.section {
  display: none;
}

.section.active {
  display: block;
}

.text-muted-foreground {
  color: #6c757d;
}

.mb-4 {
  margin-bottom: px;
}


.price-list {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-width: 600px;
  margin: 0 auto;
}

.price-list h3 {
  font-size: 18px;
  color: #007bff; /* Blue heading */
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

table th, table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

table th {
  background-color: #f7f7f7;
  font-weight: bold;
}

.exclusive-deal {
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.exclusive-deal strong {
  color: #007bff; /* Blue text for the phone number */
}

.container {
  display: flex;
  max-width: 1400px;
  margin: 5px auto;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
  overflow: hidden;
}
.container h1{
  color: #007bff;

}
.image {
  flex: 1;
  /* background-color: #f0f0f0; */
}
.image img {
  width: 100vh;
  height: auto;
}
.content {
  flex: 1;
  padding: 20px;
  /* background-color: #ffffff; */
}
h2 {
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 1px;
  color: #4169e1;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 5px 0;
  font-size: 15px;
}
p {
  margin-top: 15px;
  line-height: 1.6;
  font-size: 15px;
}












/* media queries  */
@media (max-width:991px){

    html{
        font-size:55%;
    }

    header{
        padding:1.5rem 1rem;
    }

    section{
        padding:1.5rem;
    }
    .home{
      min-height: 60vh;
      /* min-width: 50vh; */
      background:url(../public/image1/Tulip\ 1.jpg) no-repeat;
      background-size: contain;
      background-position: left;
      /* background-attachment: fixed; */
      display: flex;
      align-items: center;
      justify-content: center;
      /* border: 5rem solid red; */
  }
  
  .home form{
      margin-top: 12rem;
      /* margin-left: 1px; */
      /* margin-left: 20rem; */
      /* margin-right: 80rem; */
      background:#fff;
      /* background:transparent; */
      border-radius: .5rem;
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.2);
      border:.1rem solid rgba(0,0,0,.2);
      /* width:70rem; */
      width:35rem;
      padding:2rem;
  }
    .container {
      display: block;
      max-width: 1400px;
      margin: 5px auto;
      /* border: 1px solid #ccc; */
      border-radius: 8px;
      overflow: hidden;
    }
    .image {
      flex: 1;
      /* background-color: #f0f0f0; */
    }
    .image img {
      width: 70vh;
      height: auto;
    }
    .content {
      flex: 1;
      padding: 20px;
      /* background-color: #ffffff; */
    }
    h2 {
      font-size: 30px;
      margin-bottom: 10px;
      margin-top: 20px;
      color: #4169e1;
    }
    ul {
      list-style-type: none;
      padding: 0;
    }
    li {
      margin: 5px 0;
      font-size: 15px;
    }
    p {
      margin-top: 15px;
      line-height: 1.6;
      font-size: 15px;
    }

}

@media (max-width:768px){

    header .icons #menu-bars{
        display: inline-block;
    }

    header .navbar{
        position: absolute;
        left:0; right:0; top:100%;
        background:#f7f7f7;
        border-top: .1rem solid #333;
        border-bottom: .1rem solid #333;
        transition: .2s linear;
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    }

    header .navbar.active{
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }

    header .navbar a{
        display: block;
        padding:1.5rem;
        margin:1.5rem;
        background:#fff;
        border:.1rem solid #333;
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.1);
        border-radius: .5rem;
    }

    .home{
      min-height: 80vh;
      /* min-width: 50vh; */
      background:url(../public/image1/Tulip\ 1.jpg) no-repeat;
      background-size: contain;
      background-position: left;
      /* background-attachment: fixed; */
      display: flex;
      align-items: center;
      justify-content: center;
      /* border: 5rem solid red; */
  }
  
  .home form{
      margin-top: 12rem;
      /* margin-left: 1px; */
      /* margin-left: 20rem; */
      /* margin-right: 80rem; */
      background:#fff;
      /* background:transparent; */
      border-radius: .5rem;
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.2);
      border:.1rem solid rgba(0,0,0,.2);
      /* width:70rem; */
      width:35rem;
      padding:2rem;
  }
  .container {
    display: block;
    max-width: 1400px;
    margin: 5px auto;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    overflow: hidden;
  }
  .image {
    flex: 1;
    /* background-color: #f0f0f0; */
  }
  .image img {
    width: 50vh;
    height: auto;
  }
  .content {
    flex: 1;
    padding: 20px;
    /* background-color: #ffffff; */
  }
  h2 {
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #4169e1;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    margin: 5px 0;
    font-size: 15px;
  }
  p {
    margin-top: 15px;
    line-height: 1.6;
    font-size: 15px;
  }

}

@media (max-width:450px){

    html{
        font-size:50%;
    }

    .contact .row form .inputBox input{
        width: 100%;
    }

    .home{
      min-height: 50vh;
      /* min-width: 50vh; */
      background:url(../public/image1/Tulip\ 1.jpg) no-repeat;
      /* background-size: contain; */
      background-position: left;
      /* background-attachment: fixed; */
      display: flex;
      align-items: center;
      justify-content: center;
      /* border: 5rem solid red; */
  }
  
  .home form{
      margin-top: 35rem;
      margin-left: 1px;
      /* margin-left: 20rem; */
      /* margin-right: 80rem; */
      background:#fff;
      /* background:transparent; */
      border-radius: .5rem;
      box-shadow: 0 .5rem 1rem rgba(0,0,0,.2);
      border:.1rem solid rgba(0,0,0,.2);
      /* width:70rem; */
      width:40rem;
      padding:2rem;
  }

  .container {
    display: block;
    max-width: 1400px;
    margin: 5px auto;
    /* border: 1px solid #ccc; */
    border-radius: 8px;
    overflow: hidden;
  }
  .image {
    flex: 1;
    /* background-color: #f0f0f0; */
  }
  .image img {
    width: 50vh;
    height: auto;
  }
  .content {
    flex: 1;
    padding: 20px;
    /* background-color: #ffffff; */
  }
  h2 {
    font-size: 30px;
    margin-bottom: 10px;
    margin-top: 20px;
    color: #4169e1;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    margin: 5px 0;
    font-size: 15px;
  }
  p {
    margin-top: 15px;
    line-height: 1.6;
    font-size: 15px;
  }
}
